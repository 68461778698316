<div class="page-deskboard">

  <div class="row">
    <div class="col-xxxl-3 col-md-3 col-sm-6">
      <nb-card class="status-card">
        <div class="icon-container">
          <div class="icon status-primary" (click)="this.cms.router.navigate(['accounting/report/summary'])">
            <!-- <i [ngClass]="'nb-lightbulb'"></i> -->
            <nb-icon icon="ios-pie" pack="ion"></nb-icon>
          </div>
        </div>

        <div class="details">
          <div class="title h6">Quỹ: {{(summaryReport?.Cash + summaryReport?.CashInBank) | currency:'VND'}}</div>
          <div class="status paragraph-2">Tiền mặt: {{summaryReport?.Cash | currency:'VND'}}</div>
          <div class="status paragraph-2">Tiền ngân hàng: {{summaryReport?.CashInBank | currency:'VND'}}</div>
          <div class="status paragraph-2">Vàng: {{summaryReport?.Gold | currency:'VND'}}</div>
          <!-- <div class="status paragraph-2">Tổng quỹ: {{(summaryReport?.Cash + summaryReport?.CashInBank) | currency:'VND'}}</div> -->
        </div>
      </nb-card>
    </div>
    <div class="col-xxxl-3 col-md-3 col-sm-6">
      <nb-card class="status-card">
        <div class="icon-container">
          <div class="icon status-info" (click)="this.cms.router.navigate(['accounting/report/cash-flow'])">
            <nb-icon icon="android-cart" pack="ion"></nb-icon>
          </div>
        </div>

        <div class="details">
          <div class="title h6">Doanh thu</div>
          <div class="status paragraph-2">Doanh thu: {{summaryReport?.Revenues | currency:'VND'}}</div>
          <div class="status paragraph-2">Giảm danh thu: {{summaryReport?.DecreaseRevenues | currency:'VND'}}<br/>Chi phí: {{summaryReport?.Cost | currency:'VND'}}</div>
        </div>
      </nb-card>
    </div>
    <div class="col-xxxl-3 col-md-3 col-sm-6">
      <nb-card class="status-card">
        <div class="icon-container">
          <div class="icon status-warning" (click)="this.cms.router.navigate(['accounting/report/cash-flow'])">
            <nb-icon icon="cube" pack="ion"></nb-icon>
          </div>
        </div>

        <div class="details">
          <div class="title h6">Công nợ</div>
          <div class="status paragraph-2">Phải thu: {{summaryReport?.CustomerReceivableDebt | currency:'VND'}}<br/>Phải trả: {{summaryReport?.LiabilitiesDebt | currency:'VND'}}</div>
        </div>
      </nb-card>
    </div>
    <div class="col-xxxl-3 col-md-3 col-sm-6">
      <nb-card class="status-card">
        <div class="icon-container">
          <div class="icon status-success" (click)="this.cms.router.navigate(['accounting/report/profit-report'])">
            <nb-icon icon="arrow-graph-up-right" pack="ion"></nb-icon>
          </div>
        </div>

        <div class="details">
          <div class="title h6">Lãi/lỗ</div>
          <div class="status paragraph-2">{{summaryReport?.Profit | currency:'VND'}}</div>
        </div>
      </nb-card>
    </div>
  </div>

  <div class="row">
    <div class="col-xxxl-12 col-md-12">
      <nb-card>
        <nb-card-body>
          <div style="display: flex;">
            <div style="flex: 1;">
              <div class="row" *ngIf="this.formItem">
                <div class="col-sm-3">
                  <ngx-form-group [formGroup]="formItem" name="DateReport" label="Common.dateReport" [index]="0" [allowCopy]="false" [required]="true">
                    <ngx-select2 formControlName="DateReport" [select2Option]="select2DateReportOption" [data]="dateReportList" (selectChange)="onDateReportChange($event)"></ngx-select2>
                  </ngx-form-group>
                </div>
                <div class="col-sm-3">
                  <ngx-form-group [formGroup]="formItem" name="DateRange" label="Common.date" [index]="0" [allowCopy]="false" [required]="true">
                    <input type="text" [owlDateTime]="DateRange" [selectMode]="'range'" [owlDateTimeTrigger]="DateRange" nbInput fullWidth formControlName="DateRange" placeholder="{{'Common.date' | translate | headtitlecase}}">
                    <owl-date-time #DateRange [pickerType]="'calendar'"></owl-date-time>
                  </ngx-form-group>
                </div>
                <div class="col-sm-6">
                  <ngx-form-group [formGroup]="formItem" name="Page" label="Chi nhánh" [index]="0" [allowCopy]="false" [required]="false">
                    <ngx-select2 formControlName="Page" [select2Option]="select2OptionForPage" [data]="[]" (selectChange)="onChangePage($event)"></ngx-select2>
                  </ngx-form-group>
                </div>
                <!-- <div class="col-sm-3">
                  <ngx-form-group [formGroup]="formItem" name="ProductGroup" label="AdminProduct.Group.lablel" [index]="0" [allowCopy]="false" [required]="false">
                    <ngx-select2 formControlName="ProductGroup" [select2Option]="select2ProductGroup" [data]="groupList"></ngx-select2>
                  </ngx-form-group>
                </div> -->
              </div>
            </div>
            <div style="margin-left: 1rem;">
              <div class="row">
                <div class="col-sm-12">
                  <div class="form-group">
                    <label class="label">Action</label>
                    <button nbButton fullWidth status="success" (click)="refresh()">{{'Common.refresh' | translate | headtitlecase}}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nb-card-body>
      </nb-card>
    </div>
  </div>

  <div class="row">

    <div class="col-xxxl-3 col-xxl-3 col-lg-3 col-md-12">
      <ngx-accounting-most-of-debt [customerReceivableDebt]="customerReceivableDebt" [liabilitityDebt]="liabilitityDebt"></ngx-accounting-most-of-debt>
    </div>

    <div class="col-xxxl-9 col-xxl-9 col-lg-9 col-md-12">
      <nb-card class="size-giant chart small-header">
        <nb-tabset fullWidth>
          <nb-tab tabTitle="Hoạt động kinh doanh">
            <nb-card class="size-giant chart small-header">
              <nb-card-body class="commission-statistics-wrapper">
                <chart type="bar" [data]="costAndRevenueStatisticsData" [options]="options"></chart>
              </nb-card-body>
            </nb-card>
          </nb-tab>
          <!-- <nb-tab tabTitle="Hàng hóa">
            <nb-card class="size-giant chart small-header">
              <nb-card-body class="commission-statistics-wrapper">
                <chart type="pie" [data]="goodsStatisticsData" [options]="options"></chart>
              </nb-card-body>
            </nb-card>
          </nb-tab> -->
          <nb-tab tabTitle="Công nợ">
            <nb-card class="size-giant chart small-header">
              <nb-card-body class="commission-statistics-wrapper">
                <chart type="line" [data]="debtStatisticsData" [options]="options"></chart>
              </nb-card-body>
            </nb-card>
          </nb-tab>
          <nb-tab tabTitle="Đầu tư góp vốn">
            <nb-card class="size-giant chart small-header">
              <nb-card-body class="commission-statistics-wrapper">
                <chart type="line" [data]="investStatisticsData" [options]="options"></chart>
              </nb-card-body>
            </nb-card>
          </nb-tab>
          <nb-tab tabTitle="Dòng tiền thuần">
            <nb-card class="size-giant chart small-header">
              <nb-card-body class="commission-statistics-wrapper">
                <chart type="line" [data]="debtCompareStatisticsData" [options]="options"></chart>
              </nb-card-body>
            </nb-card>
          </nb-tab>
          <nb-tab tabTitle="Lưu chuyển tiền tệ">
            <nb-card class="size-giant chart small-header">
              <nb-card-body class="commission-statistics-wrapper">
                <chart type="line" [data]="cashFlowStatisticsData" [options]="options"></chart>
              </nb-card-body>
            </nb-card>
          </nb-tab>
          <nb-tab tabTitle="Lãi/lỗ">
            <nb-card class="size-giant chart small-header">
              <nb-card-body class="commission-statistics-wrapper">
                <chart type="line" [data]="profitStatisticsData" [options]="options"></chart>
              </nb-card-body>
            </nb-card>
          </nb-tab>
        </nb-tabset>
      </nb-card>
    </div>
  </div>

  <!-- <div class="row">
    <div class="col-xxxl-3 col-xxl-4 col-lg-5 col-md-6">
      <ngx-temperature></ngx-temperature>
    </div>

    <div class="col-xxxl-9 col-xxl-8 col-lg-7 col-md-6">
      <ngx-electricity></ngx-electricity>
    </div>
  </div>

  <div class="row">
    <div class="col-xxxl-9 col-xl-12">
      <ngx-rooms></ngx-rooms>
    </div>

    <div class="col-xxxl-3 col-xxl-4 col-lg-7 col-md-6">
      <ngx-contacts></ngx-contacts>
    </div>

    <div class="col-xxxl-3 col-xxl-4 col-lg-5 col-md-6">
      <ngx-solar [chartValue]="solarValue"></ngx-solar>

      <ngx-kitten></ngx-kitten>
    </div>

    <div class="col-xxxl-3 col-xxl-4 col-md-5">
      <ngx-traffic></ngx-traffic>
      <ngx-weather></ngx-weather>
    </div>

    <div class="col-xxxl-6 col-xxl-12 col-md-7">
      <ngx-security-cameras></ngx-security-cameras>
    </div>
  </div> -->
</div>